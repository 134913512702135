import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import MyPagination from "../Pagination";
import { Download } from "@mui/icons-material";

export default function BillingManagement() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed in JS

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);

  useEffect(() => {
    fetchData();
  }, [currentPage, year, month, searchTerm]);

  const fetchData = () => {
    setLoading(true);
    setData(null);

    const query = new URLSearchParams({
      offset: (currentPage - 1) * 12,
      search: searchTerm,
      year: year,
      month: month,
    }).toString();

    fetch(`/api/customerbilling/managementdata?${query}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderTableHeaders = () => (
    <>
      <TableCell>SN</TableCell>
      <TableCell>Account No</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Period</TableCell>
      <TableCell>Sewer</TableCell>
      <TableCell>Water</TableCell>
      <TableCell>Arrears</TableCell>
      <TableCell>Amount</TableCell>
    </>
  );

  const renderTableRows = () => {
    if (!data || !data.data) return null;

    return data.data.map((billing, index) => (
      <TableRow
        onClick={() => {
          window.location.href = "/billing/" + billing.ID;
        }}
        sx={{ cursor: "pointer" }}
        key={billing.ID}
      >
        <TableCell>
          <Chip label={(currentPage - 1) * 12 + index + 1} />
        </TableCell>
        <TableCell>{billing.Acc_No}</TableCell>
        <TableCell>{billing.Name}</TableCell>
        <TableCell>
          <Chip label={billing.Period} />
        </TableCell>
        <TableCell>{billing.Sewer}</TableCell>
        <TableCell>{billing.Water}</TableCell>
        <TableCell>
          <Chip
            color={billing.Arrears == 0 ? "success" : "error"}
            label={billing.Arrears}
          />
        </TableCell>
        <TableCell>
          <Chip
            color={billing.BAmount == 0 ? "success" : "warning"}
            label={billing.BAmount}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Box marginTop={1}>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Billing Management</Typography>
        </Box>
        <Box
          display="flex"
          gap={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            displayEmpty
            size="small"
          >
            <MenuItem value={""}>Any</MenuItem>
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            {[
              currentYear - 1,
              currentYear - 2,
              currentYear - 3,
              currentYear - 4,
              currentYear - 5,
              currentYear - 6,
              currentYear - 7,
              currentYear - 8,
              currentYear - 9,
            ].map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            displayEmpty
            size="small"
          >
            <MenuItem value={""}>Any</MenuItem>
            <MenuItem value={currentMonth}>Month</MenuItem>
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((m, index) => (
              <MenuItem key={index} value={index + 1}>
                {m}
              </MenuItem>
            ))}
          </Select>
          <TextField
            sx={{ flexGrow: 1 }}
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length > 0) {
                setMonth("");
                setYear("");
              } else {
                setMonth(currentMonth);
                setYear(currentYear);
              }
            }}
          />
          <Button color="secondary" variant="contained">
            <Download />
          </Button>
        </Box>
        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ overflowX: "auto", minHeight: "50vh" }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table sx={{ minWidth: "800px" }}>
                <TableHead>
                  <TableRow>{renderTableHeaders()}</TableRow>
                </TableHead>
                <TableBody>{renderTableRows()}</TableBody>
              </Table>
            )}
          </Box>
          <Divider />
          <MyPagination
            totalPages={data ? Math.ceil(data.total / 12) : 0}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Card>
      </Stack>
    </Box>
  );
}
